<template>
  <b-card
    no-body
    class="p-3"
  >
    <b-row align-h="center">
      <b-col
      >
        <validation-observer ref="formRef">
          <b-form @submit.prevent>

            <!-- contact -->
            <b-row>
              <b-col md="12">
                <b-form-group :label="$t('apps.masterDataContact.singular.type')">
                  <b-form-checkbox-group
                      v-model="form.type"
                      :options="options.type"
                      value-field="item"
                      text-field="name"
                      :disabled="actions.isPreview"
                    />
                  </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                  :label="$t('globalSingular.code')"
                  class="mb-1"
                  label-for="code"
                >
                  <b-form-input
                    id="code"
                    size="sm"
                    v-model="form.code"
                    :placeholder="$t('globalSingular.auto')"
                    :disabled="actions.isPreview"
                  />
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                  :label="$t('apps.masterDataContact.singular.brandName')"
                  class="mb-1"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="contact"
                    rules="required"
                  >
                    <b-form-input
                      id="brand"
                      size="sm"
                      v-model="form.brand"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Company / Brand / Person / Contact Name"
                      :disabled="actions.isPreview"
                    />
                    <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  :label="$t('apps.masterDataContact.singular.npwp')"
                  class="mb-1">
                <validation-provider
                  #default="{ errors }"
                  name="NPWP"
                >
                  <b-form-input
                    id="npwp"
                    size="sm"
                    v-model="form.brandNpwp"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="!actions.isPreview ? $t('apps.masterDataContact.placeholder.npwp')  : null"
                    :disabled="actions.isPreview"
                  />
                </validation-provider>
                </b-form-group>
              </b-col>

            </b-row>

            <!-- detail -->
            <b-row>
              <b-col md="3">
              <b-form-group :label="$t('apps.masterDataContact.singular.companyPhone')">
                <b-input-group
                    size="sm">
                  <b-input-group-prepend is-text>
                    +62
                  </b-input-group-prepend>
                  <cleave
                    id="phone"
                    v-model="form.brandPhone"
                    class="form-control"
                    :raw="false"
                    :options="options.phone"
                    :disabled="actions.isPreview"
                    placeholder="1234 567 8900"
                  />
                </b-input-group>
              </b-form-group>
              </b-col>

              <b-col md="5">
                <b-form-group :label="$t('apps.masterDataContact.singular.companyWebsite')">
                  <validation-provider
                    #default="{ errors }"
                    name="brand"
                  >
                    <b-form-input
                      id="category-code"
                      size="sm"
                      v-model="form.brandWebsite"
                      :state="errors.length > 0 ? false:null"
                      placeholder="company.id"
                      :disabled="actions.isPreview"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group :label="$t('apps.masterDataContact.singular.companyMail')">
                  <validation-provider
                    #default="{ errors }"
                    name="Company Mail"
                    rules="email"
                  >
                    <b-form-input
                      id="company-mail"
                      size="sm"
                      v-model="form.brandEmail"
                      :state="errors.length > 0 ? false:null"
                      placeholder="company@mail.com"
                      :disabled="actions.isPreview"
                    />
                  <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="3">
                <b-form-group :label="$t('apps.masterDataContact.singular.nppkp')">
                  <b-input-group
                      size="sm">
                    <cleave
                      id="nppkp"
                      v-model="form.brandNppkp"
                      class="form-control"
                      :raw="false"
                      :options="options.nppkp"
                      :disabled="actions.isPreview"
                      placeholder="NPPKP 1234 567 8900"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group :label="$t('apps.masterDataContact.singular.TOP')">
                <validation-provider
                    #default="{ errors }"
                    name="officeId"
                  >
                    <v-select
                    inputId="company-top"
                    v-model="form.termOfPayment"
                    :options="LOV.top"
                    :state="errors.length > 0 ? false:null"
                    :disabled="actions.isPreview"
                    :placeholder="!actions.isPreview ? 'Term Of Payment' : null"
                  />
                  <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="3" v-if="company.plan_id > 2">
                <b-form-group :label="$t('globalSingular.office')" label-for="officeId">
                  <validation-provider
                    #default="{ errors }"
                    name="officeId"
                  >
                    <v-select
                      id="officeId"
                      v-model="form.officeId"
                      :options="LOV.offices"
                      :reduce="field => field.id"
                      label="name"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                      class="select-size-sm"
                    />
                    <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="3" v-if="form.type.includes('CTSP') || form.type.includes('CTCS')">
                <b-form-group :label="$t('apps.masterDataTax.moduleName')" label-for="officeId">
                  <validation-provider
                    #default="{ errors }"
                    name="taxId"
                  >
                    <v-select
                      id="taxId"
                      v-model="form.taxId"
                      :options="LOV.taxes"
                      :reduce="field => field.id"
                      label="name"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                      class="select-size-sm"
                    />
                    <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="3">
                <validation-provider
                    #default="{ errors }"
                    name="Company Mail"
                    rules="required"
                  >
                  <b-form-group :label="$t('apps.masterDataContact.singular.grouping')">
                    <b-form-checkbox
                      v-model="form.isGroup"
                      :value="true"
                      :disabled="actions.isPreview"
                      :state="errors.length > 0 ? false:null"
                      class="mr-0 custom-control-inline"
                    />
                    {{ $t('apps.masterDataContact.singular.isGroup') }}
                    </b-form-group>
                  </validation-provider>
              </b-col>

              <b-col md="4" v-if="form.isGroup">
                <b-form-group :label="$t('apps.masterDataContact.singular.groupContact')">
                  <v-select
                    id="region"
                    v-model="form.contactParentId"
                    :options="LOV.groupContact"
                    :reduce="field => field.id"
                    :getOptionLabel="option => option.label"
                    :disabled="actions.isPreview"
                    @option:selected="handleRegion"
                    placeholder="Contact Group"
                    class="select-size-sm">
                    <template v-slot:option="option">
                      <small>{{ option.label }}</small>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

            </b-row>

            <!-- Header: contact Person -->
            <div class="mt-1 d-flex">
              <feather-icon
                icon="UserIcon"
                size="18"
              />
              <h6 class="mb-0 ml-50">
                Contact Person
              </h6>
            </div>

            <!-- contact Person-->
            <b-row class="mt-1">
              <b-col md="8">
                <b-form-group :label="$t('apps.masterDataContact.singular.contactName')">
                  <validation-provider
                    #default="{ errors }"
                    name="contactPerson"
                  >
                    <b-form-input
                      id="category-code"
                      size="sm"
                      v-model="form.picName"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Contact Person Name"
                      :disabled="actions.isPreview"
                    />
                    <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>


              <b-col md="4">
                <b-form-group :label="$t('apps.masterDataContact.singular.contactPersonPhone')">
                  <b-input-group
                      size="sm">
                    <b-input-group-prepend is-text>
                      +62
                    </b-input-group-prepend>
                    <cleave
                      id="phone"
                      v-model="form.picHandphone"
                      class="form-control"
                      :raw="false"
                      :options="options.phone"
                      :disabled="actions.isPreview"
                      placeholder="21 890 567"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Header: Address -->
            <div class="mt-1 d-flex justify-content-between">
              <h6 class="mb-0 ml-50"> <feather-icon
                icon="MapPinIcon"
                size="18"
              />
                {{ $t('apps.masterDataContact.singular.billingAddress') }}
              </h6>

            </div>


            <!-- Address -->
            <b-row class="mt-1">
              <b-col md="12">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Address"
                  >
                    <b-form-textarea
                      id="address"
                      size="sm"
                      v-model="form.billingAddress"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="!actions.isPreview ? $t('apps.masterDataContact.placeholder.billingAddressDetail')  : null"
                      :disabled="actions.isPreview"
                      rows="3"
                    />
                    <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>

                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group :label="$t('apps.masterDataContact.singular.postalCode')">
                  <validation-provider
                    #default="{ errors }"
                    name="Kode POS"
                  >
                    <b-form-input
                      id="company-mail"
                      size="sm"
                      v-model="form.billingPostalCode"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Postal Code"
                      :disabled="actions.isPreview"
                    />
                  <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="5">
                <b-form-group :label="$t('apps.masterDataContact.singular.billingRegionCode')">
                  <validation-provider
                    #default="{ errors }"
                    name="Region"
                  >
                    <v-select
                      id="region"
                      v-model="form.billingRegionCode"
                      :options="LOV.regions"
                      :reduce="field => field.code"
                      :getOptionLabel="option => option.province.name && option.name"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                      @option:selected="handleRegion"
                      placeholder="City"
                      class="select-size-sm">
                    <template v-slot:option="option">
                      <small>{{ option.name }} ( {{ option.province.name }} )</small>
                    </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="5">
                <b-form-group :label="$t('apps.masterDataContact.singular.billingProvince')">
                  <validation-provider
                    #default="{ errors }"
                    name="Province"
                    rules="required"
                  >
                    <v-select
                      id="province"
                      v-model="form.billingProvinceCode"
                      :options="LOV.provinces"
                      :reduce="field => field.code"
                      label="name"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                      @option:selected="handleProvince"
                      class="select-size-sm"
                      placeholder="province"
                    />
                    <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
              <!-- toggle button -->
                <b-button
                  v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                  variant="flat-info"
                  v-b-toggle.collapse-1
                  size="sm">
                  Advance
                </b-button>
              </b-col>
            </b-row>

            <b-collapse id="collapse-1" class="mt-2">
              <b-card class="mb-0 border">
                <b-card-text class="card-text">
                  <!-- Header: Billing Address -->
                  <div class="mt-1 d-flex">
                    <feather-icon
                      icon="MapPinIcon"
                      size="18"
                    />
                    <h6 class="mb-0 ml-50">
                      Billing {{ $t('apps.masterDataContact.singular.billingAddress') }}
                    </h6>
                  </div>

                  <!-- Billing Address -->
                  <b-row class="mt-1">
                    <b-col md="12">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Address"
                        >
                          <b-form-textarea
                            id="address"
                            size="sm"
                            v-model="form.shippingAddress"
                            :state="errors.length > 0 ? false:null"
                            :placeholder="!actions.isPreview ? $t('apps.masterDataContact.placeholder.billingAddressDetail')  : null"
                            :disabled="actions.isPreview"
                            rows="3"
                          />
                          <small class="text-danger">{{ errors[0]}}</small>
                        </validation-provider>

                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group :label="$t('apps.masterDataContact.singular.postalCode')">
                        <validation-provider
                          #default="{ errors }"
                          name="Kode POS"
                        >
                          <b-form-input
                            id="company-mail"
                            size="sm"
                            v-model="form.shippingPostalCode"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Postal Code"
                            :disabled="actions.isPreview"
                          />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="5">
                      <b-form-group :label="$t('apps.masterDataContact.singular.billingRegionCode')">
                        <validation-provider
                          #default="{ errors }"
                          name="Region"
                        >
                          <v-select
                            id="region"
                            v-model="form.shippingRegionCode"
                            :options="LOV.regions"
                            :reduce="field => field.code"
                            :getOptionLabel="option => option.province.name && option.name"
                            :state="errors.length > 0 ? false:null"
                            :disabled="actions.isPreview"
                            @option:selected="handleRegion"
                            placeholder="City"
                            class="select-size-sm">
                          <template v-slot:option="option">
                            <small>{{ option.name }} ( {{ option.province.name }} )</small>
                          </template>
                          </v-select>
                          <small class="text-danger">{{ errors[0]}}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="5">
                      <b-form-group :label="$t('apps.masterDataContact.singular.billingProvince')">
                        <validation-provider
                          #default="{ errors }"
                          name="Province"
                        >
                          <v-select
                            id="province"
                            v-model="form.shippingProvinceCode"
                            :options="LOV.provinces"
                            :reduce="field => field.code"
                            label="name"
                            :state="errors.length > 0 ? false:null"
                            :disabled="actions.isPreview"
                            @option:selected="handleProvince"
                            class="select-size-sm"
                            placeholder="province"
                          />
                          <small class="text-danger">{{ errors[0]}}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row class="mt-1">
                    <b-col md="6">
                      <b-form-group :label="$t('apps.masterDataContact.singular.AR')">
                      <validation-provider
                        #default="{ errors }"
                        name="Receivable Account"
                      >
                        <v-select
                          id="ar"
                          v-model="form.receivableCoaMapping"
                          :options="LOV.accountAR"
                          :reduce="field => field.id"
                          label="label"
                          :state="errors.length > 0 ? false:null"
                          :disabled="actions.isPreview"
                          class="select-size-sm"
                          :placeholder="!actions.isPreview ? $t('apps.masterDataContact.placeholder.accounrAR')  : null"
                        />
                      </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group :label="$t('apps.masterDataContact.singular.AP')">
                      <validation-provider
                        #default="{ errors }"
                        name="Payable Account"
                      >
                        <v-select
                          id="ap"
                          v-model="form.payableCoaMapping"
                          :options="LOV.accountAP"
                          :reduce="field => field.id"
                          label="label"
                          :state="errors.length > 0 ? false:null"
                          :disabled="actions.isPreview"
                          class="select-size-sm"
                          :placeholder="!actions.isPreview ? $t('apps.masterDataContact.placeholder.accountAP')  : null"
                        />
                      </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-card>
            </b-collapse>


            <!-- Header: Finance Accounting -->
            <div class="mt-1 d-flex justify-content-between">
              <h6 class="mb-0 ml-50"> <feather-icon
                icon="TrendingUpIcon"
                size="18"
              />
                {{ $t('apps.masterDataContact.singular.finance') }}
              </h6>
            </div>

            <!-- List Bank -->
            <b-row class="mb-2">
              <b-col>
                <b-table
                  id="refFormTable"
                  ref="refFormTable"
                  fixed
                  no-provider-paging
                  no-provider-filtering
                  :fields="tableColumns"
                  :items="form.bankInformation"
                  primary-key="id"
                  show-empty
                  class="mt-2 position-relative"
                >
                  <!-- Custom Header Column -->
                  <template #head(actions)>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      class="mx-auto"
                    />
                  </template>
                  <!-- Column: Account -->
                  <template #cell(bankName)="{ index }">
                    <b-form-group>
                        <b-form-input
                          v-model="form.bankInformation[index].bankName"
                          :placeholder="!actions.isPreview ? $t('apps.masterDataContact.placeholder.bankName')  : null"
                          :disabled="actions.isPreview"
                        />
                    </b-form-group>
                  </template>
                  <!-- Column: Is Contact -->
                   <template #cell(isContact)="{ index }">
                      <b-form-group>
                        <b-form-checkbox-group
                            v-model="form.bankInformation[index].isContact"
                            :options="options.IsContact"
                            value-field="value"
                            text-field="names"
                            :disabled="actions.isPreview"
                          />
                       </b-form-group>
                  </template>
                  <!-- Column: Debit Amount -->
                  <template #cell(bankAccountName)="{ index }">
                    <b-form-group>
                        <b-form-input
                          v-model="form.bankInformation[index].bankAccountName"
                          :placeholder="!actions.isPreview ? $t('apps.masterDataContact.placeholder.bankAccountName')  : null"
                          :disabled="actions.isPreview"
                        />
                    </b-form-group>
                  </template>
                  <!-- Column: Credit Amount -->
                  <template #cell(bankOffice)="{ index }">
                    <b-form-group>
                        <b-form-input
                          v-model="form.bankInformation[index].bankOffice"
                          :placeholder="!actions.isPreview ? $t('apps.masterDataContact.placeholder.bankOffice')  : null"
                          :disabled="actions.isPreview"
                        />
                    </b-form-group>
                  </template>
                  <!-- Column: Credit Amount -->
                  <template #cell(bankAccountNumber)="{ index }">
                    <b-form-group>
                        <b-form-input
                          v-model="form.bankInformation[index].bankAccountNumber"
                          :placeholder="!actions.isPreview ? $t('apps.masterDataContact.placeholder.bankAccountNumber')  : null"
                          :disabled="actions.isPreview"
                        />
                    </b-form-group>
                  </template>
                  <!-- Column: Actions -->
                  <template #cell(actions)="{ index }">
                    <b-button
                      variant="flat-danger"
                      class="btn-icon"
                      :disabled="form.bankInformation.length <= 1"
                      @click="removeRow(index)"
                    >
                      <feather-icon icon="MinusIcon" />
                    </b-button>
                  </template>
                  <!-- Costum Footer -->
                  <template v-slot:custom-foot="{}" v-if="!umpetin">
                    <b-tr>
                      <b-td :class="['pl-0', 'd-flex', 'align-items-baseline', actions.isPreview ? 'justify-content-end': 'justify-content-between']">
                        <b-button
                          v-if="!actions.isPreview"
                          variant="flat-primary"
                          @click="addRow"
                        >
                          <feather-icon
                            icon="PlusIcon"
                          />
                          {{ $t('globalActions.addMore') }}
                        </b-button>
                      </b-td>
                      <b-td>&nbsp;</b-td>
                    </b-tr>
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <div class="mt-3 d-flex flex-sm-column flex-md-row justify-content-between">
              <b-button
                class="mb-sm-1 mb-md-0"
                :variant="actions.isPreview ? 'outline-secondary' : 'outline-primary'"
                :to="{ name: 'apps-master-data-contact-Home-list'}"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                />
                {{ $t('globalActions.backToList') }}
              </b-button>

              <b-button
                v-if="actions.isPreview"
                class=" mb-sm-1 mb-md-0"
                variant="outline-danger"
                @click="handleDelete"
              >
                <feather-icon
                  icon="Trash2Icon"
                />
                {{ $t('globalActions.delete') }}
              </b-button>

              <b-button
                v-if="!actions.isPreview"
                variant="primary"
                type="submit"
                @click="handleSubmit"
              >
                <feather-icon
                  icon="SaveIcon"
                />
                {{ actions.isEditMode ? $t('globalActions.update') : $t('globalActions.create') }}
              </b-button>

              <b-button
                v-else
                variant="outline-primary"
                @click="changeToEdit"
              >
                <feather-icon
                  icon="EditIcon"
                />
                {{ $t('globalActions.changeToEdit') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>

</template>

<script>
import { ref, onMounted, computed } from '@vue/composition-api'
// import i18n from '@/libs/i18n'
import {
  BCard,
  BCardText,
  BCollapse,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckboxGroup,
  BFormCheckbox,
  BForm,
  BFormTextarea,
  BButton,
  BInputGroupPrepend,
  BInputGroup,
  BTable,
  BTr,
  BTd,
  VBToggle
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import useLocalization from '@/comp-functions/utils/useLocalization'
import { required } from '@validations'
import Cleave from 'vue-cleave-component'
import VuexStore from '@/store'

import 'cleave.js/dist/addons/cleave-phone.us'

import useFormResource from '@/comp-functions/useFormResource'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BCollapse,
    BFormGroup,
    BFormInput,
    BFormCheckboxGroup,
    BFormCheckbox,
    BForm,
    BFormTextarea,
    BButton,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BInputGroupPrepend,
    BInputGroup,
    BTable,
    BTr,
    BTd,
    Cleave
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  setup () {
    const {
      paramsId,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store
    } = useFormResource({ url: 'master/contact', localeContextPath: 'apps.masterDataContact.singular.contact', redirectPathName: 'apps-master-data-contact-Home' })

    const company = computed(() => {
      return VuexStore.state.user.currentCompany
    })
    const umpetin = ref(true)
    // LOV (select option)
    const LOV = ref({
      offices: [],
      districs: [],
      regions: [],
      provinces: [],
      groupContact: [],
      accountAR: [],
      accountAP: [],
      taxes: [],
      accounts:[],
      top: [
        7,
        14,
        30,
        60
      ],
    })

    const { $t } = useLocalization()

    const form = ref({
      type: [],
      officeId: null,
      taxId: null,
      code: null,
      brand: null,
      brandEmail: null,
      brandPhone: null,
      brandWebsite: null,
      brandNpwp: null,
      brandNppkp: null,
      termOfPayment: null,
      isGroup: false,
      contactParentId: null,
      picName: null,
      picHandphone: null,
      billingAddress: null,
      billingCountryCode: null,
      billingProvinceCode: null,
      billingRegionCode: null,
      billingPostalCode: null,
      shippingAddress: null,
      shippingCountryCode: null,
      shippingProvinceCode: null,
      shippingRegionCode: null,
      shippingPostalCode: null,
      receivableCoaMapping: null,
      payableCoaMapping: null,
      bankInformation: [
        {
          bankName: '',
          isContact: false,
          bankAccountName: '',
          bankOffice: '',
          bankAccountNumber: ''
        },
        {
          bankName: '',
          isContact: false,
          bankAccountName: '',
          bankOffice: '',
          bankAccountNumber: ''
        }
      ]
    })

    // Add row bankInformation
    const addRow = () => {
      form.value.bankInformation.push({ bankName: '', bankAccountName: null, bankOffice: null, bankAccountNumber: null })
    }

    // Remove row bankInformation
    const removeRow =  (index) => {
      if (form.value.bankInformation.length <= 1) return false
      form.value.bankInformation.splice(index, 1)
    }

    // will get data to show the preview
    const getDataPreview = async () => {
      const data = await show()
      form.value.type                   = data.type || []
      form.value.officeId               = data.office_id
      form.value.code                   = data.code
      form.value.brand                  = data.brand
      form.value.brandEmail             = data.brand_email
      form.value.brandPhone             = data.brand_phone
      form.value.brandWebsite           = data.brand_website
      form.value.brandNpwp              = data.brand_npwp
      form.value.brandNppkp             = data.brand_npkp
      form.value.termOfPayment          = data.term_of_payment
      form.value.isGroup                = data.is_group
      form.value.contactParentId        = data.contact_parent_id
      form.value.picName                = data.pic_name
      form.value.picHandphone           = data.pic_handphone
      form.value.billingAddress         = data.billing_address
      form.value.billingCountryCode     = data.billing_country_code
      form.value.billingProvinceCode    = data.billing_province_code
      form.value.billingRegionCode      = data.billing_region_code
      form.value.billingPostalCode      = data.billing_postal_code
      form.value.shippingAddress        = data.shipping_address
      form.value.shippingCountryCode    = data.shipping_country_code
      form.value.shippingProvinceCode   = data.shipping_province_code
      form.value.shippingRegionCode     = data.shipping_region_code
      form.value.shippingPostalCode     = data.shipping_postal_code
      form.value.receivableCoaMapping   = data.receivable_coa_mapping
      form.value.payableCoaMapping      = data.payable_coa_mapping
      form.value.bankInformation        = data.bank_information
    }

    const getProvinces = async (params = '') => {
      LOV.value.provinces = await get({ url: `data/province${params}`})
    }
    const getRegions = async () => {
      LOV.value.regions = await get({ url: 'data/region'})
    }
    const getDistricts = async () => {
      LOV.value.districs = await get({ url: 'data/district'})
    }

    const getGroupContact = async () => {
      LOV.value.groupContact = await get({ url: 'value/contact/group'})
    }

    const getAccountAP = async () => {
      LOV.value.accountAP = await get({ url: 'master/accounts/child?account_type_code=LIB'})
        const getDefaultCoa = await get({ url: 'value/get-default-coa/CROSSACCOUNT'})
      
      if(form.value.receivableCoaMapping == null || form.value.receivableCoaMapping == '' ){
        form.value.receivableCoaMapping = getDefaultCoa[0].account_id
      }
    }

    const getAccountAR = async () => {
      LOV.value.accountAR = await get({ url: 'master/accounts/child?account_type_code=AST'})
       const getDefaultCoa = await get({ url: 'value/get-default-coa/CASHADVANCE'})
      
      if(form.value.payableCoaMapping == null || form.value.payableCoaMapping == '' ){
        form.value.payableCoaMapping = getDefaultCoa[0].account_id
      }
    }

    const handleRegion = ({province_code}) => {
      form.value.billingProvinceCode = province_code
    }

    const handleProvince = () => {
      form.value.billingRegionCode = null
    }

    // Get offices
    const getOffice = async () => {
      LOV.value.offices = await get({ url: 'master/offices'})
    }

    // Get taxes
    const getTaxes = async () => {
      LOV.value.taxes = await get({ url: 'value/tax'})
    }

    const tableColumns = ref([])

    onMounted(() => {
      if (paramsId) {
        actions.value.isPreview = true
        getDataPreview()
      }
      if (company.value.plan_id > 2) {
        getOffice()
      }
      getGroupContact()
      getTaxes()
      getProvinces()
      getRegions()
      getDistricts()
      getAccountAR()
      getAccountAP()
      tableColumns.value = [
        { key: 'bankName', label: $t('apps.masterDataContact.singular.bankName'), width: 'auto', thClass: 'bg-transparent pl-0', tdClass: 'align-top pl-0' },
        { key: 'isContact', label: $t('apps.masterDataContact.singular.isContact'), width: 'auto', thClass: 'bg-transparent pl-0', tdClass: 'align-top pl-0' },
        { key: 'bankAccountName', label: $t('apps.masterDataContact.singular.bankAccountName'), width: 'auto', thClass: 'bg-transparent', tdClass: 'align-top'},
        { key: 'bankOffice', label: $t('apps.masterDataContact.singular.bankOffice'), width: '24%', thClass: 'bg-transparent width-25-per', tdClass: 'align-top'},
        { key: 'bankAccountNumber', label: $t('apps.masterDataContact.singular.bankAccountNumber'), width: '24%', thClass: 'bg-transparent width-25-per', tdClass: 'align-top'},
        { key: 'actions', width: '12%', thClass: 'bg-transparent text-right width-10-per  pr-0', tdClass: 'align-top text-right pr-0'}
      ]
    })

    return {
      company,
      handleRegion,
      handleProvince,
      required,
      form,
      addRow,
      removeRow,
      paramsId,
      formRef,
      umpetin,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store,
      selected: [],
      tableColumns,
      LOV,
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'ID'
        },
        type :
        [
          { item: 'CTCS', name: 'Customer' },
          { item: 'CTSP', name: 'Supplier' },
          { item: 'CTAG', name: 'Agent'}
        ],
        group : [{ item: true, name: 'Group Contact' }],
        IsContact:[
          { names: '', value: true}
        ]
      }
    }
  },
  methods: {
    handleDelete () {
      this.destroy(this.$swal)
    },
    handleSubmit () {
      this.store({
        $swal: this.$swal,
        data: this.form
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
